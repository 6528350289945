import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <polygon
          id="Shape"
          stroke="currentColor"
          stroke-width="5"
          stroke-linecap="round"
          stroke-linejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"></polygon>
        <path d="M20,25 L40, 65 60,25" stroke="currentColor" stroke-width="10" fill="none" />

        {/* <path d="M10,50 L50, 2 90,70" stroke="white" stroke-width="10" fill="none" /> */}
      </g>
    </g>
  </svg>
);

export default IconLogo;
